import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import SbEditable from "storyblok-react"
import useStoryblok from "../lib/storyblok"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import DynamicComponent from "../components/DynamicComponent"

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
    }
  }
`

const IndexPage = ({ data, location }) => {
  let story = data.storyblokEntry
  story = useStoryblok(story, location)

  const components = story.content.body.map(blok => {
    return <DynamicComponent blok={blok} key={blok._uid} />
  })

  return <Layout isHomepage={true}>{components}</Layout>
}

export default IndexPage
